import { GlobalVars } from './global/cearth_fr';
import { defaultEnvironment } from './environment.defaults'
import DicoKeys from '@dk/cearth_fr.json';

export const environment = {
  ...defaultEnvironment,
  production: false,
  name: 'preprod',
  path: {
    root: `https://preprod.${GlobalVars.name}.fr`,
    img: `https://preprod.${GlobalVars.name}.fr/assets/images/${GlobalVars.name}`,
    appRoot: defaultEnvironment.path.appRoot,
    auth: 'https://accounts.preprod.clicandearth.fr',
    api: defaultEnvironment.path.api,
    api2: 'https://api.preprod.jobology.fr',
    prod: `https://www.${GlobalVars.name}.fr`,
    blog: {
      url: 'https://www.cache.jobology.fr/wp-json/wp/v2/',
      tagId: 719
    }
  },
  GlobalVars: GlobalVars,
  DK: DicoKeys,
  gtmID: 'GTM-M4D2SJ5'
};
